/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { connect, useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl, FormattedDate } from 'react-intl';
import { toAbsoluteUrl } from '../../../../_helpers';
import * as auth from '../../../../../app/modules/Auth/_redux/authRedux';
import FreePersonIcon from '../../../../../assets/free_person.png';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { logout } from '../../../../../app/modules/Auth/_redux/authCrud';
import { useAlert } from 'react-alert';
import { BACKEND_URL } from '../../../../../config';
import { addComma } from '../../../../../app/utils/comma';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useIsWalletActive } from '../../../../../app/hooks/useFeatureFlag';
import Carousel from '../../carousel/Carousel';
import * as actions from '../../../../../app/modules/ECommerce/_redux/groupManage/groupActions';
import penSvg from '../../../../../app/assets/pen.svg';
import ProgressBar from '../../progess-bar/ProgressBar';
import { checkPreview, useCustomTheme } from '../../../../../app/hooks/useCustomTheme';

import { alpha } from '@mui/material';

const Screenmode = {
  UserProfile: 'profile',
  Edit: 'edit',
  ChangePassword: 'password'
};

const QuickUser = props => {
  const alert = useAlert();
  const history = useHistory();
  const { intl } = props;
  const dispatch = useDispatch();
  const { user, shop, authToken, shopSetting, shopPointandWalletSetting, regisField } = useSelector(
    state => state.auth
  );
  const activeWallet = useIsWalletActive();
  const { groups } = useSelector(state => state.groups);

  const customField = regisField?.customField;
  const custom1 = customField?.[0];
  const custom2 = customField?.[1];
  const custom3 = customField?.[2];
  const customField1 = custom1?.field ? { [custom1?.field]: user?.[custom1?.field] } : {};
  const customField2 = custom2?.field ? { [custom2?.field]: user?.[custom2?.field] } : {};
  const customField3 = custom3?.field ? { [custom3?.field]: user?.[custom3?.field] } : {};
  const memberExpire = user?.memberExpire;
  const memberExpireSetting = shop?.memberExpire;

  const pointText =
    shopPointandWalletSetting?.point[0][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.point' });
  const walletText =
    shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.credit' });

  const [changePasswordForm, setChangePasswordForm] = useState({ old_password: '', password: '', re_password: '' });
  const [mode, setMode] = useState(Screenmode.UserProfile);
  const [userProfile, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthdate: '',
    shippingAddress: '',
    remark: '',
    phone: '',
    countryDialCode: null
  });

  const logoutClick = async () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    const loginPath = user.type === 'admin' ? '/admin/login' : `/shop/${user.seqId}/login`;
    await logout();
    props.logout();
    history.push(loginPath);
  };

  const handleClearLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    mappingUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    dispatch(actions.fetchGroups());
  }, []);

  const mappingUserProfile = () => {
    if (user) {
      setUserProfile({
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.gender,
        email: user.email,
        phone: user.phone,
        birthdate: user.birthdate,
        shippingAddress: user.shippingAddress,
        remark: user.remark,
        countryDialCode: user.countryDialCode,
        ...customField1,
        ...customField2,
        ...customField3
      });
    }
  };
  const test = async () => {
    await axios.post(`${BACKEND_URL}/announcement/test`, { headers: { Authorization: `Bearer ${authToken}` } });
    alert.success(
      intl.formatMessage({
        id: 'recheck.notification.alert'
      })
    );
  };

  const onSubmit = async event => {
    event.preventDefault();
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (userProfile.email && !emailRegex.test(userProfile.email)) {
      alert.success(
        intl.formatMessage({
          id: 'alert.invalid.email.format'
        })
      );
      return;
    }

    if (!userProfile.phone) {
      alert.error(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.INVALID_PHONE'
        })
      );

      return;
    }

    try {
      const { data } = await axios.patch(`${BACKEND_URL}/customer/${user.id}`, {
        ...userProfile,
        countryDialCode: userProfile.countryDialCode
      });
      if (data.success) {
        alert.success(
          intl.formatMessage({
            id: 'alert.edit.profile.success'
          })
        );
      }
      //user.point, user.credit from data.data is a field in user not shopUser where the actual points are stored; they must be removed.
      const { point, credit, ...validFieldsInUser } = data.data;

      dispatch({
        type: auth.actionTypes.UserLoaded,
        payload: {
          user: { ...user, ...validFieldsInUser, id: validFieldsInUser._id, seqId: user.seqId, type: user.type }
        }
      });
      setMode(Screenmode.UserProfile);
    } catch (err) {
      console.log(err);
      alert.error(err.response?.data?.message);
    }
  };

  const onSubmitChangePassword = async event => {
    event.preventDefault();

    if (changePasswordForm.re_password !== changePasswordForm.password) {
      alert.error('Password do not match. Please enter password again.');
      setChangePasswordForm({ old_password: '', password: '', re_password: '' });
      return;
    }

    if (changePasswordForm.re_password.length < 6 || changePasswordForm.password.length < 6) {
      alert.error('Password must have at least 6 characters.');
      setChangePasswordForm({ old_password: '', password: '', re_password: '' });
      return;
    }

    try {
      const { data } = await axios.patch(`${BACKEND_URL}/customer/${user.id}`, changePasswordForm);
      if (data.success) {
        alert.success('Change password success.');
        dispatch({
          type: auth.actionTypes.UserLoaded,
          payload: { user: { ...data.data, id: data.data._id, seqId: user.seqId, type: user.type } }
        });
        setChangePasswordForm({ old_password: '', password: '', re_password: '' });
        setMode(Screenmode.UserProfile);
      } else {
        alert.error(data.message);
        setChangePasswordForm({ old_password: '', password: '', re_password: '' });
      }
    } catch (error) {
      alert.error(error.response.data.msg || error.response.data.message);
      setChangePasswordForm({ old_password: '', password: '', re_password: '' });
    }
  };

  const onCloseButton = () => {
    setMode(Screenmode.UserProfile);
    mappingUserProfile();
  };

  const onChange = e => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
  };

  const onDateChange = date => {
    setUserProfile({ ...userProfile, birthdate: new Date(date) });
  };

  const handleChangeRoleToCustomer = async () => {
    alert.removeAll();
    // if (window.sessionStorage.getItem('THEME_PREVIEW') || window.sessionStorage.getItem('IMG_PREVIEW')) {
    //   window.sessionstorage.clear()
    //   window.location.reload(false);
    // }

    // window.sessionStorage.clear();
    // window.location.reload(false);

    try {
      if (user.type !== 'customer') {
        const { data } = await axios.put(`${BACKEND_URL}/user/role`, { id: user.shopUserId, role: 'customer' });
        if (data.success) {
          dispatch({
            type: auth.actionTypes.UserLoaded,
            payload: { user: { ...user, type: 'customer', demoRole: user.type, loggedInBranch: data.newBranch } }
          });
          alert.success(intl.formatMessage({ id: 'alert.change.role.success' }));
        }
      } else {
        const { data } = await axios.put(`${BACKEND_URL}/user/role`, { id: user.shopUserId, role: user.demoRole });
        if (data.success) {
          dispatch({
            type: auth.actionTypes.UserLoaded,
            payload: { user: { ...user, type: user.demoRole, demoRole: user.demoRole, loggedInBranch: data.newBranch } }
          });
          alert.success(intl.formatMessage({ id: 'alert.change.role.success' }));
        }
      }
    } catch (e) {
      alert.error('Something went wrong, Please contact admin.');
    }
  };

  // const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  // const previewNav = previewTheme?.navColor;
  // const previewFont = previewTheme?.fontColor;
  // const isPreview = previewNav || previewFont ? true : false;

  // const initStyle = {
  //   background: '#1e1e2d'
  // };
  // const colorInit = {
  //   color: '##B5B5C3'
  // };

  // const [background, setBackground] = useState(initStyle);
  // const [fontColor, setFontColor] = useState(colorInit);

  // useEffect(() => {
  //   const navColor = shopSetting?.backgroundColor?.navColor;
  //   const fontColor = shopSetting?.backgroundColor?.fontColor;
  //   const isShopSetting = navColor || fontColor ? true : false;

  //   if (isPreview && previewNav) {
  //     setBackground({ background: previewNav });
  //   }
  //   if (isPreview && previewFont) {
  //     setFontColor({ color: previewFont });
  //   }
  //   if (isShopSetting && fontColor) {
  //     setFontColor({ color: fontColor });
  //   }
  //   if (isShopSetting && navColor) {
  //     setBackground({ background: navColor });
  //   }
  // }, [shopSetting]);

  const customTheme = useCustomTheme();
  const style = customTheme?.profile;
  const existsPreview = checkPreview();

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
      style={{ background: style.bgColor, color: style.color }}
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2">
        <h3 className="font-weight-bold m-0">
          {mode === Screenmode.UserProfile && intl.formatMessage({ id: 'user.profile' })}
          {mode === Screenmode.Edit && intl.formatMessage({ id: 'edit.your.profile' })}
          {mode === Screenmode.ChangePassword && intl.formatMessage({ id: 'change.password' })}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
          onClick={() => onCloseButton()}
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={mode}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames="fade"
        >
          <div>
            {user && mode === Screenmode.UserProfile && (
              <div className="offcanvas-content pr-5 mr-n5 fade-user pt-5">
                <div className="d-flex align-items-center mt-1 justify-content-between">
                  <div className="symbol symbol-100 mr-5 symbol-light-success">
                    <div className="d-flex justify-content-between navi-link mb-4">
                      <div className="navi-text">
                        {user.memberGroups?.length > 0 ? (
                          <>
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'menu.member.group' })}
                            </div>
                            {user.memberGroups.map(group => (
                              <div key={group._id} style={{ color: style.color }}>
                                {group.name}
                              </div>
                            ))}
                          </>
                        ) : (
                          <div style={{ color: style.color }} className={`font-weight-bold`}>
                            {intl.formatMessage({ id: 'menu.member.group' })} <br />
                            {intl.formatMessage({ id: 'menu.no.group' })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {memberExpireSetting?.isExpireMember?.isEnable && memberExpire.isEnable ? (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div style={{ color: style.color }} className={`font-weight-bold`}>
                          {intl.formatMessage({ id: 'member.expiration.date' })}
                        </div>
                        {memberExpire.expireDate ? (
                          <div style={dateStyle}>
                            <FormattedDate value={memberExpire.expireDate} day="2-digit" month="long" year="numeric" />
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div style={{ color: style.color }} className={`font-weight-bold d-flex flex-column`}>
                        <div>{intl.formatMessage({ id: 'member.expiration.date' })}</div>
                        <div className="d-flex justify-content-center">{' -'}</div>
                      </div>
                    </>
                  )}
                </div>

                <Carousel groups={groups} user={user} />

                <div className="navi navi-spacer-x-0 pl-3" style={{ paddingTop: '8px' }}>
                  <style>{gridStyle}</style>

                  <div className="d-flex justify-content-end">
                    {(user && user.type === 'customer' ? shop?.customerCanEditCustomer ?? true : true) && (
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG src={penSvg} onClick={() => setMode(Screenmode.Edit)}></SVG>
                      </span>
                    )}
                  </div>

                  <div className="grid-container">
                    {user.firstName && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.firstname' })}
                            </div>
                            <div style={{ color: style.color }}>{user.firstName}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.lastName && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.lastname' })}
                            </div>
                            <div style={{ color: style.color }}>{user.lastName}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.gender && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.gender' })}
                            </div>
                            <div style={{ color: style.color }}>
                              {user?.gender === 'male'
                                ? intl.formatMessage({ id: 'gender.male' })
                                : user?.gender === 'female'
                                ? intl.formatMessage({ id: 'gender.female' })
                                : user?.gender === 'lgbtq+'
                                ? intl.formatMessage({ id: 'gender.lgbtq+' })
                                : intl.formatMessage({ id: 'gender.not.specified' })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.phone && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.mobile' })}
                            </div>
                            <div style={{ color: style.color }}>{user.phone}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.email && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.email' })}
                            </div>
                            <div style={{ color: style.color }}>{user.email}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.birthdate && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.birthdate' })}
                            </div>
                            <div style={{ color: style.color }}>
                              {user.birthdate ? (
                                <FormattedDate value={user.birthdate} day="2-digit" month="long" year="numeric" />
                              ) : (
                                '-'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="grid-item">
                      <div className="navi-link mb-4">
                        <div className="navi-text">
                          <div style={{ color: style.color }} className={`font-weight-bold`}>
                            {pointText}
                          </div>
                          <div style={{ color: style.color }}>{addComma(user.expirePoint + user.point)}</div>
                        </div>
                      </div>
                    </div>

                    {activeWallet && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {walletText}
                            </div>
                            <div style={{ color: style.color }}>{addComma(user.expireWallet + user.credit)}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/*new*/}

                    {custom1?.active && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {custom1?.name}
                            </div>
                            <div style={{ color: style.color }}>{user?.[custom1?.field] || '-'}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {custom2?.active && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {custom2.name}
                            </div>
                            <div style={{ color: style.color }}>{user?.[custom2?.field] || '-'}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {custom3?.active && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {custom3?.name}
                            </div>
                            <div style={{ color: style.color }}>{user?.[custom3?.field] || '-'}</div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div className="grid-item">
                      <div className="navi mt-2 mb-5">
                        <a href="#" className="navi-item">
                          <span className="navi-link p-0 pb-2">
                            <span className="navi-icon mr-1">
                              <span className="svg-icon-lg svg-icon-primary">
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Mail-notification.svg')}></SVG>
                              </span>
                            </span>
                            <span
                              style={{ color: 'rgb(181, 181, 195)' }}
                              className={`navi-text  text-hover-primary`}
                            >
                              {user.email}
                            </span>
                          </span>
                        </a>
                      </div>
                    </div> */}
                  </div>

                  <div className="separator separator-solid mt-4 mb-5" style={{ borderColor: '#C4C4C4' }} />

                  <ProgressBar groups={groups} user={user} intl={intl} />

                  {shop && user && shop.isDemo && (user.type !== 'customer' || user.demoRole !== 'customer') ? (
                    <div className="navi-link mb-2">
                      <div className="navi-text">
                        <div style={{ color: style.color }} className={`font-weight-bold`}>
                          {intl.formatMessage({ id: 'user.change.to.customer' })}
                        </div>
                        <FormControlLabel
                          control={
                            <Switch
                              sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                  color: style.demoRoleColor,
                                  '&:hover': {
                                    backgroundColor: alpha(style.demoRoleColor)
                                  }
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                  backgroundColor: style.demoRoleColor
                                },
                                '& .MuiSwitch-thumb': {
                                  backgroundColor: style.demoRoleColor
                                }
                              }}
                              checked={user.type === 'customer'}
                              onClick={() => handleChangeRoleToCustomer()}
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="d-flex justify-content-around my-3 ">
                    <button
                      style={{ background: style.clearBgColor, color: style.clearColor }}
                      className="btn  btn-bold mb-3 px-4"
                      onClick={handleClearLocalStorage}
                    >
                      {intl.formatMessage({ id: 'clear.website.data' })}
                    </button>

                    <button
                      style={{ background: style.logoutBgColor, color: style.logoutColor }}
                      className="btn  btn-bold mb-3 px-4"
                      onClick={logoutClick}
                    >
                      {intl.formatMessage({ id: 'sign.out' })}
                    </button>
                  </div>

                  {/* {user.shippingAddress && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        {intl.formatMessage({ id: 'user.shipping.address' })}
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.shippingAddress}</div>
                    </div>
                  </div>}
                  {user.remark && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        {intl.formatMessage({ id: 'user.remark' })}
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.remark}</div>
                    </div>
                  </div>} */}

                  {shop && user.type === 'customer' ? null : (
                    <div
                      className="separator separator-solid my-7"
                      style={{ borderWidth: '2px', borderColor: '#C4C4C4' }}
                    />
                  )}

                  <div className="d-flex justify-content-center" style={{ marginTop: `10px` }}>
                    {user && user.type !== 'customer' && (
                      <button
                        style={{ background: style.notifyBgColor, color: style.notifyColor }}
                        className="btn btn-bold"
                        onClick={() => {
                          test();
                        }}
                      >
                        {intl.formatMessage({ id: 'recheck.notification' })}
                      </button>
                    )}
                  </div>

                  <div className="d-flex justify-content-center" style={{ marginTop: `10px` }}>
                    {existsPreview && (
                      <button
                        // style={{ background: style.notifyBgColor, color: style.notifyColor }}
                        className="btn btn-primary btn-bold"
                        onClick={() => {
                          // window.sessionStorage.clear();
                          sessionStorage.removeItem('THEME_NAVBAR_PREVIEW');
                          sessionStorage.removeItem('THEME_SIDEBAR_PREVIEW');
                          sessionStorage.removeItem('THEME_PROFILE_PREVIEW');
                          sessionStorage.removeItem('THEME_MAINHEADER_PREVIEW');
                          sessionStorage.removeItem('THEME_MAIN_PREVIEW');
                          sessionStorage.removeItem('THEME_REGIS_PREVIEW');
                          window.location.reload();
                        }}
                      >
                        {intl.formatMessage({ id: 'clear.preview' })}
                      </button>
                    )}
                  </div>

                  {shop && user.type !== 'customer' ? (
                    <ExpiredDateContainer style={{ background: style.expireBgColor, color: style.expireColor }}>
                      <DateLabelStyled>
                        {intl.formatMessage({ id: 'create.shop.date' })}:{' '}
                        {shop.createdDate ? moment(shop.createdDate).format('DD/MM/YYYY') : '-'}
                      </DateLabelStyled>
                      <DateLabelStyled>
                        {intl.formatMessage({ id: 'exipred.shop.date' })}:{' '}
                        {shop.expiryDate ? moment(shop.expiryDate).format('DD/MM/YYYY') : '-'}
                      </DateLabelStyled>
                    </ExpiredDateContainer>
                  ) : null}
                </div>
              </div>
            )}
            {user && mode === Screenmode.Edit && (
              <div className="offcanvas-content pr-5 mr-n5 fade-user" style={{ opacity: 1, transition: '700ms' }}>
                <form onSubmit={e => onSubmit(e)}>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.firstname' })}</div>
                    <input
                      className="form-control"
                      name="firstName"
                      value={userProfile.firstName}
                      onChange={e => onChange(e)}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.lastname' })}</div>
                    <input
                      className="form-control"
                      name="lastName"
                      value={userProfile.lastName}
                      onChange={e => onChange(e)}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.email' })}</div>
                    <input
                      className="form-control"
                      name="email"
                      value={userProfile.email}
                      onChange={e => onChange(e)}
                    />
                  </div>

                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.phone' })}</div>
                    <input
                      className="form-control"
                      name="phone"
                      value={userProfile.phone}
                      onChange={e => onChange(e)}
                    />
                  </div>

                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.birthdate' })}</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="form-control"
                        inputProps={{
                          style: {
                            fontFamily: `Poppins, Helvetica, "sans-serif"`,
                            fontWeight: 400,
                            color: 'rgb(70, 78, 95)',
                            display: 'block',
                            padding: '0.65rem 1rem',
                            border: '1px solid #E5EAEE',
                            borderRadius: '0.42rem'
                          },
                          'aria-readonly': true
                        }}
                        InputProps={{
                          disableUnderline: true
                        }}
                        format="dd/MM/yyyy"
                        value={userProfile.birthdate}
                        onChange={date => onDateChange(date)}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <input className="form-control"/> */}
                  </div>
                  {custom1?.active && (
                    <div className="form-group mb-4">
                      <div className="text-muted font-weight-bold">{custom1?.name}</div>
                      <input
                        className="form-control"
                        name={custom1?.field}
                        value={userProfile?.[custom1?.field]}
                        onChange={e => onChange(e)}
                      />
                    </div>
                  )}
                  {custom2?.active && (
                    <div className="form-group mb-4">
                      <div className="text-muted font-weight-bold">{custom2?.name}</div>
                      <input
                        className="form-control"
                        name={custom2?.field}
                        value={userProfile?.[custom2?.field]}
                        onChange={e => onChange(e)}
                      />
                    </div>
                  )}
                  {custom3?.active && (
                    <div className="form-group mb-4">
                      <div className="text-muted font-weight-bold">{custom3?.name}</div>
                      <input
                        className="form-control"
                        name={custom3?.field}
                        value={userProfile?.[custom3?.field]}
                        onChange={e => onChange(e)}
                      />
                    </div>
                  )}
                  {/* <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      {intl.formatMessage({ id: 'user.shipping.address' })}
                    </div>
                    <textarea className="form-control" rows="3" name="shippingAddress" value={userProfile.shippingAddress} onChange={(e) => onChange(e)} />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      {intl.formatMessage({ id: 'user.remark' })}
                    </div>
                    <textarea className="form-control" rows="3" name="remark" value={userProfile.remark} onChange={(e) => onChange(e)} />
                  </div> */}
                  <div className="d-flex justify-content-around mt-5 pt-5">
                    <button
                      type="button"
                      className="btn btn-light btn-bold"
                      onClick={() => {
                        setMode(Screenmode.UserProfile);
                        mappingUserProfile();
                      }}
                    >
                      {intl.formatMessage({ id: 'button.cancel' })}
                    </button>
                    <button type="submit" className="btn btn-bold btn-primary">
                      {intl.formatMessage({ id: 'button.submit' })}
                    </button>
                  </div>
                </form>
              </div>
            )}
            {user && mode === Screenmode.ChangePassword && (
              <div className="offcanvas-content pr-5 mr-n5 fade-user" style={{ opacity: 1, transition: '700ms' }}>
                <form onSubmit={e => onSubmitChangePassword(e)}>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_OLD_PASSWORD" />
                    </div>
                    <input
                      type="password"
                      className="form-control"
                      name="old_password"
                      value={changePasswordForm.old_password}
                      onChange={e => setChangePasswordForm({ ...changePasswordForm, old_password: e.target.value })}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.password' })}</div>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={changePasswordForm.password}
                      onChange={e => setChangePasswordForm({ ...changePasswordForm, password: e.target.value })}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.repassword' })}</div>
                    <input
                      type="password"
                      className="form-control"
                      name="re_password"
                      value={changePasswordForm.re_password}
                      onChange={e => setChangePasswordForm({ ...changePasswordForm, re_password: e.target.value })}
                    />
                  </div>
                  <div className="d-flex justify-content-around mt-5 pt-5">
                    <button
                      type="button"
                      className="btn btn-light btn-bold"
                      onClick={() => {
                        setMode(Screenmode.UserProfile);
                        mappingUserProfile();
                      }}
                    >
                      {intl.formatMessage({ id: 'button.cancel' })}
                    </button>
                    <button type="submit" className="btn btn-bold purple-button">
                      {intl.formatMessage({ id: 'button.submit' })}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default injectIntl(connect(null, auth.actions)(QuickUser));

const IconContainer = styled.div`
  width: 100px;
  height: 100px;
`;

const PersonIcon = styled.img`
  width: 100px;
  height: 100px;
`;

const ExpiredDateContainer = styled.div`
  // background-color: #17b987;
  // color: #000;
  margin: 20px 15px 20px 15px;
  padding: 10px 0 5px 0;
  text-align: center;
  border-radius: 15px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
`;

const DateLabelStyled = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const dateStyle = {
  color: 'red',
  fontWeight: 'bold'
};

const gridStyle = `
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
.grid-item .navi-text {
  word-wrap: break-word;
  word-break: break-word;
`;
